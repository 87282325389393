import { ref, set, get } from "firebase/database";
import { realtimeDb } from "../../config";

// Save the listening time for a specific audio file using an index as an identifier
export const saveAudioTime = (userId, bookId, audioIndex, listenTime) => {
    const audioRef = ref(realtimeDb, `users/${userId}/books/${bookId}/audios/${audioIndex}`);
    return set(audioRef, {
        listenTime,
        timestamp: Date.now(),
    });
};

// Fetch the saved listening times for all audios of a book
export const fetchAudioTimes = async (userId, bookId) => {
    const audioTimesRef = ref(realtimeDb, `users/${userId}/books/${bookId}/audios`);
    const snapshot = await get(audioTimesRef);
    return snapshot.exists() ? snapshot.val() : {};
};