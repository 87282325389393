import React, { useState, useEffect, useCallback } from 'react';
import '../../styles/modal_sign.css';
import '../../styles/pay_modal.css';
import CryptoJS from 'crypto-js';

import { doc, updateDoc } from 'firebase/firestore';
import { db, auth } from '../../config';

const PayInfoModal = ({ isOpen, onClose }) => {
    const [cardNumber, setCardNumber] = useState('');
    const [cardHolder, setCardHolder] = useState('');
    const [expiryDate, setExpiryDate] = useState('');
    const [cvv, setCvv] = useState('');
    const [phoneNumber, setNumber] = useState('');
    const [message, setMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [SECRET_KEY, setSecretKey] = useState('');
    const [isLogin, setIsLogin] = useState(true);

    useEffect(() => {
        const user = auth.currentUser;
        if (user) {
            setSecretKey(user.uid);
        }
    }, []);

    const encryptData = (data) => {
        if (!SECRET_KEY) {
            setErrorMessage('Құпия кілт жоқ.');
            return;
        }
        return CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();
    };

    const decryptData = useCallback((ciphertext) => {
        if (!SECRET_KEY) {
            setErrorMessage('Құпия кілт жоқ.');
            return;
        }
        const bytes = CryptoJS.AES.decrypt(ciphertext, SECRET_KEY);
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }, [SECRET_KEY]);

    useEffect(() => {
        if (isOpen) {
            const storedData = localStorage.getItem('cardData');
            if (storedData) {
                const decryptedData = decryptData(storedData);
                setCardNumber(decryptedData?.cardNumber || '');
                setCardHolder(decryptedData?.cardHolder || '');
                setExpiryDate(decryptedData?.expiryDate || '');
                setCvv(decryptedData?.cvv || '');
            } else {
                setCardNumber('');
                setCardHolder('');
                setExpiryDate('');
                setCvv('');
            }
        }
    }, [isOpen, decryptData]);

    const handleSubmit = (e) => {
        e.preventDefault();
        try {
            const cardData = { cardNumber, cardHolder, expiryDate, cvv };
            const encryptedData = encryptData(cardData);
            if (encryptedData) {
                localStorage.setItem('cardData', encryptedData);
                setMessage('Карта мәліметтері сәтті сақталды.');
                setErrorMessage('');
            }
        } catch (error) {
            setErrorMessage('Карта мәліметтерін сақтау кезінде қате пайда болды.');
            setMessage('');
        }
    };

    const handleNumberChange = (e) => {
        const input = e.target.value.replace(/\D/g, '').substring(0, 11); // Keep only numbers
        const formattedNumber = `${input}`;
        setNumber(formattedNumber);
    };

    const handleCardNumberChange = (e) => {
        const input = e.target.value.replace(/\D/g, '').substring(0, 16); // Keep only numbers
        const formattedCardNumber = `${input.substring(0, 4)} ${input.substring(4, 8)} ${input.substring(8, 12)} ${input.substring(12, 16)}`;
        setCardNumber(formattedCardNumber);
    };

    const handleExpiryDateChange = (e) => {
        const input = e.target.value.replace(/\D/g, '').substring(0, 4); // Keep only numbers
        const formattedExpiryDate = `${input.substring(0, 2)}/${input.substring(2, 4)}`;
        setExpiryDate(formattedExpiryDate);
    };

    const handleNumberSubmit = async () => {
        try {
            if (phoneNumber) {
                const user = auth.currentUser;
                if (user) {
                    const userDocRef = doc(db, 'users', user.uid);
                    await updateDoc(userDocRef, { phoneNumber });
                    setMessage('KaspiGold нөмірі сәтті сақталды.');
                    setErrorMessage('');
                    onClose();
                } else {
                    throw new Error('Пайдаланушы анықталмады.');
                }
            } else {
                throw new Error('KaspiGold нөмірін енгізіңіз.');
            }
        } catch (error) {
            setErrorMessage(error.message);
            setMessage('');
        }
    };

    const handleSignupClick = () => setIsLogin(false);
    const handleLoginClick = () => setIsLogin(true);

    return (
        isOpen && (
            <div className="pay_modal">
                <div className='pay_modal_content'>
                    <p className="close-modal" onClick={onClose}>&times;</p>
                    <div className="form-container">
                        <div className="slide-controls">
                            <input type="radio" name="slide" id="login" checked={isLogin} readOnly />
                            <input type="radio" name="slide" id="signup" checked={!isLogin} readOnly />
                            <label htmlFor="login" className="slide login" onClick={handleLoginClick}>
                                Kaspi
                            </label>
                            <label htmlFor="signup" className="slide signup" onClick={handleSignupClick}>
                                Карта
                            </label>
                            <div className="slider-tab"></div>
                        </div>
                        <div>
                            {isLogin ? (
                                <div className="pay_modal_overlay">
                                    <input
                                        type="text"
                                        placeholder="KaspiGold нөміріңіз"
                                        className="pay_input_field"
                                        value={phoneNumber}
                                        onChange={handleNumberChange}
                                    />
                                    <div className="pay_check_content">
                                        <p class="pay_text">KaspiGold нөміріңіз сақталуына келісесіз бе?</p>
                                        <div class="pay_checkbox_container">
                                            <input type="checkbox" id="agree" />
                                            <label for="agree">
                                                <svg class="pay_check_icon" viewBox="0 0 24 24">
                                                    <path d="M20.285,6.708l-11.4,11.4a1.5,1.5,0,0,1-2.121,0l-5.4-5.4a1.5,1.5,0,0,1,2.121-2.121L7.5,15.185,18.164,4.523a1.5,1.5,0,0,1,2.121,2.185Z" fill="currentColor" />
                                                </svg>
                                            </label>
                                        </div>
                                    </div>
                                    <button className="save_button" onClick={handleNumberSubmit}>Сақтау</button>
                                </div>
                            ) : (
                                <form onSubmit={handleSubmit} className="pay_modal_overlay">
                                    <input
                                        type="text"
                                        className="pay_input_field"
                                        placeholder="1234 XXXX XXXX XXXX"
                                        value={cardNumber}
                                        onChange={handleCardNumberChange}
                                        required
                                    />
                                    <input
                                        type="text"
                                        placeholder="MM/YY"
                                        className="pay_input_field"
                                        value={expiryDate}
                                        onChange={handleExpiryDateChange}
                                        maxLength={5}
                                    />
                                    <input
                                        type="password"
                                        placeholder="CVV"
                                        className="pay_input_field"
                                        maxLength={3}
                                        value={cvv}
                                        onChange={(e) => setCvv(e.target.value)}
                                    />
                                    <div className="pay_check_content">
                                        <p class="pay_text">
                                            Карта мәліметтеріңіз құрылғының өзінде құпия түрде сақталады
                                        </p>
                                        <div class="pay_checkbox_container">
                                            <input type="checkbox" id="agree" />
                                            <label for="agree">
                                                <svg class="pay_check_icon" viewBox="0 0 24 24">
                                                    <path d="M20.285,6.708l-11.4,11.4a1.5,1.5,0,0,1-2.121,0l-5.4-5.4a1.5,1.5,0,0,1,2.121-2.121L7.5,15.185,18.164,4.523a1.5,1.5,0,0,1,2.121,2.185Z" fill="currentColor" />
                                                </svg>
                                            </label>
                                        </div>
                                    </div>
                                    <button type="submit" className="save_button">Сақтау</button>
                                </form>
                            )}
                            {errorMessage && <div className="message error">{errorMessage}</div>}
                            {message && <div className="message success">{message}</div>}
                        </div>
                    </div>
                </div>
            </div>
        )
    );
};

export default PayInfoModal;