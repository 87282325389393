import React, { useState, useRef, useEffect } from 'react';
import '../../styles/range.css';
import prevIcon from '../../icons/previous.svg';
import nextIcon from '../../icons/next.svg';
import playIcon from '../../icons/play.svg';
import pauseIcon from '../../icons/pause.svg';

import '../../styles/FooderControl.css';

const FooderControl = ({ id, audioName, audioUrl, isPlaying: parentIsPlaying, onPlay, onNext, onPrev }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const audioRef = useRef(null);

    useEffect(() => {
        const audioElement = audioRef.current;

        const handleTimeUpdate = () => {
            setCurrentTime(audioElement.currentTime);
            localStorage.setItem(`audioTime_${audioUrl}`, audioElement.currentTime.toString());
        };

        const handleLoadedMetadata = () => {
            setDuration(audioElement.duration);
        };

        const handleEnded = () => {
            onNext();
        };

        audioElement.addEventListener('timeupdate', handleTimeUpdate);
        audioElement.addEventListener('loadedmetadata', handleLoadedMetadata);
        audioElement.addEventListener('ended', handleEnded);

        return () => {
            audioElement.removeEventListener('timeupdate', handleTimeUpdate);
            audioElement.removeEventListener('loadedmetadata', handleLoadedMetadata);
            audioElement.removeEventListener('ended', handleEnded);
        };
    }, [audioUrl, onNext]);

    useEffect(() => {
        setIsPlaying(parentIsPlaying);
        if (parentIsPlaying && audioRef.current) {
            audioRef.current.play();
        } else if (!parentIsPlaying && audioRef.current) {
            audioRef.current.pause();
        }
    }, [parentIsPlaying, audioUrl]);

    const togglePlay = () => {
        if (audioRef.current) {
            if (isPlaying) {
                audioRef.current.pause();
                setIsPlaying(false);
                onPlay(null);
            } else {
                audioRef.current.play();
                setIsPlaying(true);
                onPlay(audioRef.current);
            }
        }
    };

    const handleNext = () => {
        onNext();
        if (audioRef.current) {
            setIsPlaying(true);
            audioRef.current.play();
        }
    };

    const handlePrev = () => {
        onPrev();
        if (audioRef.current) {
            setIsPlaying(true);
            audioRef.current.play();
        }
    };

    const handleSeek = (event) => {
        const seekTime = parseFloat(event.target.value);
        if (audioRef.current) {
            audioRef.current.currentTime = seekTime;
            setCurrentTime(seekTime);
        }
    };

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    return (
        <div className="fooder_control">
            <audio ref={audioRef} src={audioUrl} id={id} autoPlay />
            <div className="container_fooder_audio">
                <div className="fooder_control_content">
                    <div className="audio-info">
                        <h3 className="fooder_control_name">{audioName}</h3>
                    </div>
                    <div className="fooder_buttons_control">
                        <button className="button-cont" onClick={handlePrev}>
                            <img className="icon-f" src={prevIcon} alt="Backward" />
                        </button>
                        <button className="button-cont" onClick={togglePlay}>
                            <img className="icon-play-fooder" src={isPlaying ? pauseIcon : playIcon} alt={isPlaying ? 'Pause' : 'Play'} />
                        </button>
                        <button className="button-cont" onClick={handleNext}>
                            <img className="icon-f" src={nextIcon} alt="Forward" />
                        </button>
                    </div>
                </div>
                <div className="content">
                    <span>{formatTime(currentTime)}</span>
                    <input
                        className="seek-bar"
                        type="range"
                        min={0}
                        max={duration}
                        value={currentTime}
                        onChange={handleSeek}
                    />
                    <span>{formatTime(duration)}</span>
                </div>
            </div>
        </div>
    );
};

export default FooderControl;