import React, { useState, useRef, useEffect } from 'react';
import '../../styles/audio.css';
import playIcon from '../../icons/play.svg';
import pauseIcon from '../../icons/pause.svg';

const AudioControl = ({
    audioUrl,
    onSaveTime,
    savedTime,
    id,
    isPlaying: parentIsPlaying,
    onPlay,
    onNext = () => {},
    audioFileName,
    playingAudio
}) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const audioRef = useRef(null);

    useEffect(() => {
        const audioElement = audioRef.current;

        const handleTimeUpdate = () => {
            setCurrentTime(audioElement.currentTime);
            onSaveTime(audioElement.currentTime);
        };

        const handleLoadedMetadata = () => {
            setDuration(audioElement.duration);
            if (savedTime) {
                audioElement.currentTime = savedTime;  // Set to saved time or start at 0
                setCurrentTime(savedTime); // Update currentTime state for UI
            }
        };

        const handleEnded = () => {
            onNext();
        };

        audioElement.addEventListener('timeupdate', handleTimeUpdate);
        audioElement.addEventListener('loadedmetadata', handleLoadedMetadata);
        audioElement.addEventListener('ended', handleEnded);

        return () => {
            audioElement.removeEventListener('timeupdate', handleTimeUpdate);
            audioElement.removeEventListener('loadedmetadata', handleLoadedMetadata);
            audioElement.removeEventListener('ended', handleEnded);
        };
    }, [audioUrl, savedTime, onSaveTime, onNext]);

    useEffect(() => {
        setIsPlaying(parentIsPlaying);
    }, [parentIsPlaying]);

    const togglePlay = () => {
        const audioElement = audioRef.current;
        if (isPlaying) {
            audioElement.pause();
            setIsPlaying(false);
            onPlay(null);
        } else {
            onPlay(audioElement);
            audioElement.play().catch(err => console.error('Play failed:', err));
            setIsPlaying(true);
        }
    };

    const handleSeekChange = (event) => {
        const seekTime = event.target.value;
        audioRef.current.currentTime = seekTime;
        setCurrentTime(seekTime);
    };

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    return (
        <div>
            <audio ref={audioRef} src={audioUrl} id={id} />
            <div className='container'>
                <div className='audio-control-container'>
                    <section className='audio-control-left'>
                        <button onClick={togglePlay} aria-label={isPlaying ? 'Pause' : 'Play'}>
                            <img
                                className='icon-play'
                                src={playingAudio === audioRef.current ? pauseIcon : playIcon}
                                alt={isPlaying ? 'Pause' : 'Play'}
                            />
                        </button>
                    </section>
                    <div className='content'>
                        <div className='content-section'>
                            <p className='audio-file-name'>{audioFileName}</p>
                        </div>
                        <div className='content-section top-5'>
                            <span>{formatTime(currentTime)}</span>
                            <input
                                type="range"
                                min="0"
                                max={duration}
                                value={currentTime}
                                onChange={handleSeekChange}
                                className="seek-bar"
                            />
                            <span>{formatTime(duration)}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AudioControl;