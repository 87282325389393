import React from 'react';

// components
import Modal from '../ui/Read';
import PayButton from '../functions/Payment';
import ModalSuccess from '../modals/ModalSuccess';
import CustomAudioPlayer from '../components/CustomAudioPlayer';

// styles
import '../style/BooksUI.css';

// icons
import comments from '../../icons/comments.svg';
import save_1 from '../../icons/bookmark_1.svg';
import save_2 from '../../icons/bookmark_2.svg';
import playIcon from '../../icons/play.svg';

function BooksUI(props) {
    return (
        <div className="books_container">
            <input type="search" className="search_input" placeholder="Іздеу" value={props.searchQuery} onChange={e => props.setSearchQuery(e.target.value)} />
            <div className='genre_container'>
                <button onClick={() => props.setSelectedGenre('')} className={`genre_button ${props.selectedGenre === '' ? 'active' : ''}`}>Барлығы</button>
                {props.genres.map(genre => <button key={genre} onClick={() => props.setSelectedGenre(genre)} className={`genre_button ${props.selectedGenre === genre ? 'active' : ''}`}>
                    {genre}
                </button>)}
            </div>
            <main className="books_content" ref={props.swiperRef}>
                {props.filteredBooks.map((book, index) => {
                    const distanceFromActive = Math.abs(index - props.currentIndex);
                    const zIndex = props.books.length - distanceFromActive;
                    const rotation = -20 * (index - props.currentIndex);
                    const transform = `rotateY(${rotation}deg) translateZ(150px)`;
                    return <div key={book.id} className={`swiper_slide ${index === props.currentIndex ? 'swiper_slide_active' : ''}`} style={{
                        transform,
                        zIndex: index === props.currentIndex ? props.books.length : zIndex
                    }} onClick={() => props.handleClickSlide(index)}>
                        {book.type === 'bookAudio' ? <>
                            <img src={book.imageUrl} alt={book.title} className="card_image" />
                            <div className="card_details">
                                <div className="card_header">
                                    <button className='booksIconButton' onClick={() => props.handleCommentsToggle(book.id)}>
                                        <img className='iconsBooks' src={comments} alt="Comments" />
                                    </button>
                                    <div className="card_fooder">
                                        {props.isBookInUserCollection(book.id) ? <button className="read-button" onClick={() => props.handleReadButtonClick(book)}><img src={playIcon} alt='playicon' /></button> : !book.isPaid ? <button className="read-button" onClick={() => props.handleReadButtonClick(book)}><img src={playIcon} alt='playicon' /></button> : <>
                                            <div className='listen_demo'>
                                                <p>Демо тыңдау</p>
                                                <CustomAudioPlayer src={book.demo} />
                                            </div>
                                            <PayButton productId={book.id} productName={book.title} productPrice={book.price} showSuccessModal={props.showSuccessModal} />
                                        </>}
                                    </div>
                                    <button className='booksIconButton' onClick={() => props.handleSave(book.id)}>
                                        <img className='iconsBooks' src={props.savedBooks.includes(book.id) ? save_2 : save_1} alt="Save" />
                                        <span className="count">{book.savesCount}</span>
                                    </button>
                                </div>
                                {props.isCommentsOpen && props.selectedBook && props.selectedBook.id === book.id && <div className="comments-section">
                                    {props.commentsData.map((comment, idx) => <div key={idx} className="comment">
                                        <div className="comment-header">
                                            <img src={comment.photoURL} alt={comment.displayName} className="comment-avatar" />
                                            <span className="comment-author">{comment.displayName}</span>
                                        </div>
                                        <p>{comment.text}</p>
                                    </div>)}
                                    <div className='inputSection'>
                                        <input type="text" className='commentInput' value={props.newComment} onChange={e => props.setNewComment(e.target.value)} placeholder="Жаңа пікір енгізіңіз..." />
                                        <button className='commentsButton' onClick={() => props.handleCommentSubmit(book.id)}>Жіберу</button>
                                    </div>
                                </div>}
                                <h6 className="card_title">{book.title}</h6>
                                <h6 className="card_title">Авторы: {book.author}</h6>
                                {book.sound && <p className="card_title">Дыбыстаушы: {book.sound}</p>}
                            </div>
                        </> : <>
                            <img src={book.imageUrl} alt={book.title} className="card_image" />
                            <div className="card_details">
                                <div className="card_header">
                                    <button className='booksIconButton' onClick={() => props.handleCommentsToggle(book.id)}>
                                        <img className='iconsBooks' src={comments} alt="Comments" />
                                        <span className="count">{props.commentsData.length}</span>
                                    </button>
                                    <button className="read-button" onClick={() => props.handleReadButtonClick(book)}><img src={playIcon} alt='playicon' /></button>
                                    <button className='booksIconButton' onClick={() => props.handleSave(book.id)}>
                                        <img className='iconsBooks' src={props.savedBooks.includes(book.id) ? save_2 : save_1} alt="Save" />
                                        <span className="count">{book.savesCount}</span>
                                    </button>
                                </div>
                                {props.isCommentsOpen && props.selectedBook && props.selectedBook.id === book.id && <div className="comments-section">
                                    {props.commentsData.map((comment, idx) => <div key={idx} className="comment">
                                        <div className="comment-header">
                                            <img src={comment.photoURL} alt={comment.displayName} className="comment-avatar" />
                                            <span className="comment-author">{comment.displayName}</span>
                                        </div>
                                        <p>{comment.text}</p>
                                    </div>)}
                                    <div>
                                        <input type="text" className='commentInput' value={props.newComment} onChange={e => props.setNewComment(e.target.value)} placeholder="Жаңа пікір енгізіңіз..." />
                                        <button className='commentsButton' onClick={() => props.handleCommentSubmit(book.id)}>Жіберу</button>
                                    </div>
                                </div>}
                                <h6 className="card_title">{book.title}</h6>
                                <h6 className="card_title">Авторы: {book.author}</h6>
                                {book.sound && <p className="card_title">Дыбыстаушы: {book.sound}</p>}
                            </div>
                        </>}
                    </div>;
                })}
            </main>
            {props.isSuccessModalOpen && <ModalSuccess message={props.successMessage} onClose={() => props.setIsSuccessModalOpen(false)} />}
            {props.isModalOpen && <Modal book={props.selectedBook} closeModal={() => props.setIsModalOpen(false)} />}
        </div>);
}

export default BooksUI