import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';
import { db, auth } from '../../config';

// components
import HomeUI from '../contents/HomeUI';
import BuyModal from '../modals/BuyModal';

// styles
import '../../styles/home.css';

const Home = ({ handlePlayAudio }) => {
    const [newBooks, setNewBooks] = useState([]);
    const [newSongs, setNewSongs] = useState([]);
    const [favoriteBooks, setFavoriteBooks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [userBooks, setUserBooks] = useState([]);
    const [isPayModalOpen, setIsPayModalOpen] = useState(false);
    const [selectedBook, setSelectedBook] = useState({});
    const user = auth.currentUser;
    localStorage.setItem('favoriteBooks', []);
    
    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            if (user) {
                const fetchData = async () => {
                    try {
                        const cachedFavoriteBooks = localStorage.getItem('favoriteBooks');
                        const cachedNewBooks = localStorage.getItem('newBooks');
                        const cachedSongs = localStorage.getItem('newSongs');

                        if (cachedFavoriteBooks && cachedNewBooks && cachedSongs) {
                            setFavoriteBooks(JSON.parse(cachedFavoriteBooks));
                            setNewBooks(JSON.parse(cachedNewBooks));
                            setNewSongs(JSON.parse(cachedSongs));
                            setLoading(false);
                        } else {
                            const userDocRef = doc(db, 'users', user.uid);
                            const userDocSnap = await getDoc(userDocRef);

                            if (userDocSnap.exists()) {
                                const userData = userDocSnap.data();
                                const favoriteBookIds = userData.saves || [];

                                const fetchedFavoriteBooks = await Promise.all(
                                    favoriteBookIds.map(async (bookId) => {
                                        const bookDocRef = doc(db, 'books', bookId);
                                        const bookDocSnap = await getDoc(bookDocRef);
                                        if (bookDocSnap.exists()) {
                                            return { id: bookId, ...bookDocSnap.data() };
                                        }
                                        return null;
                                    })
                                );

                                const nonNullFavoriteBooks = fetchedFavoriteBooks.filter(book => book !== null);
                                setFavoriteBooks(nonNullFavoriteBooks);
                                localStorage.setItem('favoriteBooks', JSON.stringify(nonNullFavoriteBooks));
                            }

                            const allBooksData = [];
                            const allSongsData = [];
                            const querySnapshot = await getDocs(collection(db, 'books'));

                            querySnapshot.forEach((doc) => {
                                const bookData = doc.data();
                                if (bookData.type === 'bookAudio') {
                                    allBooksData.push({
                                        id: doc.id,
                                        title: bookData.title,
                                        imageUrl: bookData.imageUrl,
                                        author: bookData.author,
                                        type: bookData.type,
                                        audioUrls: bookData.audioUrls,
                                        audioFileNames: bookData.audioFileNames || [],
                                        price: bookData.price,
                                        isPaid: bookData.isPaid
                                    });
                                } else {
                                    allSongsData.push({
                                        id: doc.id,
                                        title: bookData.title,
                                        imageUrl: bookData.imageUrl,
                                        artist: bookData.artist,
                                        type: bookData.type,
                                        audioUrls: bookData.audioUrls,
                                        audioFileNames: bookData.audioFileNames || [],
                                        price: bookData.price
                                    });
                                }
                            });

                            setNewBooks(allBooksData);
                            setNewSongs(allSongsData);
                            localStorage.setItem('newBooks', JSON.stringify(allBooksData));
                            localStorage.setItem('newSongs', JSON.stringify(allSongsData));
                            setLoading(false);
                        }
                    } catch (error) {
                        console.error('Error fetching data: ', error);
                    }
                };

                fetchData();
            } else {
                console.error("User not authenticated.");
                setLoading(false);
            }
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            if (user) {
                const userDocRef = doc(db, 'users', user.uid);
                const userDocSnap = await getDoc(userDocRef);
                if (userDocSnap.exists()) {
                    const userData = userDocSnap.data();
                    setUserBooks(userData.books || []);
                }
            }
        });

        return () => unsubscribe();
    }, []);

    const handleCheckPlay = async (audioUrls, audioFileNames, bookId, isPaid, bookTitle, bookDescription, bookPrice) => {
        const user = auth.currentUser;
        if (user) {
            if (userBooks.includes(bookId) || isPaid === false) {
                handlePlayAudio(audioUrls, audioFileNames);
            } else {
                setSelectedBook({
                    bookId,
                    bookTitle,
                    bookDescription,
                    bookPrice,
                    audioFileNames
                });
                setIsPayModalOpen(true);
            }
        } else {
            navigate('/login');
        }
    };

    return (
        <>
            <HomeUI loading={loading} newBooks={newBooks} newSongs={newSongs} favoriteBooks={favoriteBooks} handleCheckPlay={handleCheckPlay} userBooks={userBooks} user={user} handlePlayAudio={handlePlayAudio}></HomeUI>
            <BuyModal
                isOpen={isPayModalOpen}
                onClose={() => setIsPayModalOpen(false)}
                bookId={selectedBook.bookId}
                bookName={selectedBook.bookTitle}
                bookPrice={selectedBook.bookPrice}
                bookDescription={selectedBook.bookDescription || ""}
                bookAudioCount={selectedBook.audioFileNames ? selectedBook.audioFileNames.length : 0}
            />
        </>
    );
};

export default Home;