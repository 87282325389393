import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { collection, getDocs, doc, getDoc, where, query, updateDoc, arrayUnion, arrayRemove } from 'firebase/firestore';

import { db, auth } from '../../config';
import BooksUI from '../contents/BooksUI';

const Books = ({ handleReadButtonClick }) => {
    const [books, setBooks] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedBook, setSelectedBook] = useState(null);
    const [userBooks, setUserBooks] = useState([]);
    const [selectedGenre, setSelectedGenre] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    // const [filteredIndices, setFilteredIndices] = useState([]);
    const [isCommentsOpen, setIsCommentsOpen] = useState(false);
    const [commentsData, setCommentsData] = useState([]);
    const [newComment, setNewComment] = useState('');
    const [savedBooks, setSavedBooks] = useState([]);
    const [genres, setGenres] = useState([]);
    const [genreCounts, setGenreCounts] = useState({});

    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const swiperRef = useRef(null);
    const location = useLocation();

    useEffect(() => {
        const fetchBooks = async () => {
            try {
                const queryParams = queryString.parse(location.search);
                queryParams.title = '';
                setSearchQuery('');
                const booksCollection = collection(db, 'books');
                let booksQuery = query(booksCollection);
                if (selectedGenre) {
                    booksQuery = query(booksCollection, where('genre', '==', selectedGenre));
                }
                const booksSnapshot = await getDocs(booksQuery);
                const booksData = await Promise.all(booksSnapshot.docs.map(async doc => {
                    const data = doc.data();
                    const savesCount = data.saves ? data.saves.length : 0;
                    return { id: doc.id, ...data, savesCount };
                }));

                const genreCountMap = booksData.reduce((acc, book) => {
                    const genre = book.genre;
                    acc[genre] = (acc[genre] || 0) + 1;
                    return acc;
                }, {});

                const uniqueGenres = Object.keys(genreCountMap);
                setGenres(uniqueGenres);
                setGenreCounts(genreCountMap);
                setBooks(booksData);
                // setFilteredIndices(booksData.map((_, index) => index));
                setCurrentIndex(0);
            } catch (error) {
                console.error('Error fetching books:', error);
            }
        };

        fetchBooks();
    }, [selectedGenre, location.search]);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                const fetchUserBooks = async () => {
                    try {
                        const userDocRef = doc(db, 'users', user.uid);
                        const userDocSnap = await getDoc(userDocRef);
                        if (userDocSnap.exists()) {
                            const userData = userDocSnap.data();
                            setUserBooks(userData.books || []);
                            setSavedBooks(userData.saves || []);
                        }
                    } catch (error) {
                        console.error('Error fetching user books:', error);
                    }
                };

                fetchUserBooks();
            }
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const queryParams = queryString.parse(location.search);
        const title = queryParams.title || '';
        if (title) {
            setSearchQuery(title);
        } else {
            setSearchQuery('');
        }
    }, [location.search]);

    useEffect(() => {
        setCurrentIndex(0);
    }, [searchQuery]);

    const handleClickSlide = (index) => {
        setCurrentIndex(index);
    };

    const handleReadButtonClickInternal = (book) => {
        setSelectedBook(book);
        setIsModalOpen(true);
        handleReadButtonClick(); // Trigger the external handleReadButtonClick to pause audio if needed
    };

    const isBookInUserCollection = (bookId) => {
        return userBooks.includes(bookId);
    };

    const handleCommentsToggle = async (bookId) => {
        if (isCommentsOpen && selectedBook && selectedBook.id === bookId) {
            setIsCommentsOpen(false);
            setCommentsData([]);
            setSelectedBook(null);
        } else {
            setIsCommentsOpen(true);
            const bookDocRef = doc(db, 'books', bookId);
            const bookDocSnap = await getDoc(bookDocRef);
            if (bookDocSnap.exists()) {
                const bookData = bookDocSnap.data();
                setCommentsData(bookData.comments || []);
                setSelectedBook({ id: bookId });
            }
        }
    };

    const handleCommentSubmit = async (bookId) => {
        if (newComment.trim()) {
            const bookDocRef = doc(db, 'books', bookId);
            const user = auth.currentUser;

            const userDocRef = doc(db, 'users', user.uid);
            const userDocSnap = await getDoc(userDocRef);
            const userData = userDocSnap.exists() ? userDocSnap.data() : {};

            const comment = {
                userId: user.uid,
                text: newComment,
                displayName: userData.displayName,
                photoURL: userData.photoURL,
                timestamp: new Date().toISOString(),
            };

            setSuccessMessage('Пікір қосылды')

            await updateDoc(bookDocRef, {
                comments: arrayUnion(comment)
            });
            setCommentsData([...commentsData, comment]);
            setNewComment('');
        }
    };

    const handleSave = async (bookId) => {
        const userDocRef = doc(db, 'users', auth.currentUser.uid);
        const bookDocRef = doc(db, 'books', bookId);
        if (savedBooks.includes(bookId)) {
            await updateDoc(userDocRef, {
                saves: arrayRemove(bookId)
            });
            await updateDoc(bookDocRef, {
                saves: arrayRemove(auth.currentUser.uid)
            });
            setSavedBooks(savedBooks.filter(id => id !== bookId));
        } else {
            await updateDoc(userDocRef, {
                saves: arrayUnion(bookId)
            });
            await updateDoc(bookDocRef, {
                saves: arrayUnion(auth.currentUser.uid)
            });
            setSavedBooks([...savedBooks, bookId]);
        }
    };

    const filteredBooks = books.filter(book =>
        book.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        (book.author && book.author.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (book.sound && book.sound.toLowerCase().includes(searchQuery.toLowerCase()))
    );

    return (
        <BooksUI
            books={books}
            currentIndex={currentIndex}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            selectedBook={selectedBook}
            selectedGenre={selectedGenre}
            setSelectedGenre={setSelectedGenre}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            isCommentsOpen={isCommentsOpen}
            commentsData={commentsData}
            newComment={newComment}
            setNewComment={setNewComment}
            savedBooks={savedBooks}
            genres={genres}
            genre={genreCounts[genres]}
            isSuccessModalOpen={isSuccessModalOpen}
            setIsSuccessModalOpen={setIsSuccessModalOpen}
            successMessage={successMessage}
            swiperRef={swiperRef}
            handleClickSlide={handleClickSlide}
            handleReadButtonClick={handleReadButtonClickInternal}
            isBookInUserCollection={isBookInUserCollection}
            handleCommentsToggle={handleCommentsToggle}
            handleCommentSubmit={handleCommentSubmit}
            handleSave={handleSave}
            filteredBooks={filteredBooks}
        />
    );
};

export default Books;