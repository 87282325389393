import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
    apiKey: "AIzaSyAypM-CQ97ZS7GNGsmMZr7X5tMXKkvUzDE",
    authDomain: "tynda-65659.firebaseapp.com",
    projectId: "tynda-65659",
    storageBucket: "tynda-65659.appspot.com",
    messagingSenderId: "973896355956",
    appId: "1:973896355956:web:998a578187553715b5c121",
    measurementId: "G-CLJB4DGJB5"
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const realtimeDb = getDatabase(app);

export { auth, db, storage, realtimeDb };